import React from "react";
import { createPopper } from "@popperjs/core";
import SecureLS from "secure-ls";
import {Link} from "gatsby";
import Profile from '../../img/dilegzersizlogo.png';
import '../../styles/index.css';
import '../../styles/tailwind.css';

const UserDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  let userRole = '';
  if(typeof localStorage !== "undefined"){
      const ls = new SecureLS({encodingType: 'aes'});
      userRole = ls.get('secret_key').userRole
  }
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <a
        className="text-gray-600 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
        onMouseEnter={(e) => {
            e.preventDefault();
            dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full">
            <img
              alt="profilfotofransizcaogreniyorumnavbar"
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={Profile}
            />
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
          {userRole === 'admin' || userRole === 'exercise_admin' ?
          <Link 
              to="/admin/upgraded-uyeler"
              className={
                    "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
                }
          >
              Admin Panel
          </Link>    
              : <Link
              to="/profile"
              className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
              } 
          >
              Profiliniz
          </Link>
          }
        <div className="h-0 my-2 border border-solid border-gray-200" />
          <a
              href="#"
              className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
              }
              onClick={(e) =>{
                  e.preventDefault()
                  if(typeof localStorage !== "undefined"){
                      const ls = new SecureLS({encodingType: 'aes'});
                      ls.removeAll()
                  }
                  window.location.reload()
                }
              }
          >
              Çıkış Yap
          </a>
      </div>
    </>
  );
};

export default UserDropdown;
